import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  theme,
  Image,
  Input,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router';
import { auth } from '../firebase-config';

function Redeem() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const toast = useToast();
  const [loading, setLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(false);
  const [code, setCode] = useState('');
  const navigate = useNavigate();
  const handleChange = event => setCode(event.target.value);

  useEffect(() => {
    function goHome() {
      navigate(`/login?redirect=redeem`);
    }

    const unsubscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        if (user.email == null) {
          goHome();
        }
        setLoading(false);
      } else {
        // User is signed out
        goHome();
      }
    });

    document.body.style.background = '#141416';
    return () => {
      unsubscribe();
    };
  }, [navigate]);

  function goToProfile() {
    navigate(`/profile`);
  }

  const handleSubmit= () => {
    setApiLoading(true);
    
    const functions = getFunctions();
    const verifyCode = httpsCallable(functions, 'verifyCode');
    verifyCode({ code: code })
      .then(result => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data.data;
        if (data === 'Success') {
          setTimeout(function (){
  
            toast({
              title: 'Code redemption successful!.',
              description: 'We hope you enjoy your Wave Product!',
              status: 'success',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
                      
          }, 100);
          
        } else {
          setTimeout(function (){
  
            toast({
              title: 'Code redemption failed.',
              description:
                'Double check your code and try again. If the problem persists, contact Wave Support.',
              status: 'error',
              duration: 9000,
              isClosable: true,
              position: 'bottom-right',
            });
                      
          }, 100); 
          
        }
      })
      .catch(error => {
        setTimeout(function (){
  
          toast({
            title: 'Code redemption failed.',
            description:
              'Double check your code and try again. If the problem persists, contact Wave Support.',
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'bottom-right',
          });
          console.log(error);
                    
        }, 100); 
        
      });
      // wait here for 1 second
      setTimeout(function (){
  
        setApiLoading(false);
                  
      }, 500); 
      
  };

  return (
    <ChakraProvider theme={theme}>
      {isDesktop && (
        <div>
          <Image
            src="/blurred_background.png"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: -1,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, .9)', // Adjust the last value to increase/decrease darkness
              zIndex: -2, // Ensure it's above the image but below everything else
            }}
          ></div>
          <div
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Black color with 70% opacity
              height: '120px', // Set your desired height
              width: '100%',
              position: 'fixed', // Fixed position to stay at the top of the page
              top: 0,
              zIndex: 1000, // Higher z-index to make sure it appears above other elements
            }}
          >
            <div style={{ height: 150 }}></div>
            <div
              style={{
                position: 'absolute',
                top: '60px',
                left: '200px',
                transform: 'translate(-50%, -50%)',
                zIndex: 1001,
              }}
            >
              <a href="/">
                <img
                  style={{ width: 300, height: 100 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  alt="wavelogo"
                />
              </a>
            </div>

            <div
              style={{
                position: 'absolute',
                top: '60px',
                right: '50px',
                transform: 'translate(0%, -50%)',
                zIndex: 1001,
              }}
            >
              <Button variant="solid" colorScheme="blue" onClick={goToProfile}>
                Profile
              </Button>
            </div>
          </div>
          <div
            id="content-container"
            style={{
              position: 'absolute',
              top: '40%',
              left: '50%',
              width: '30%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {!loading && (
              <div>
                <h1
                  style={{ fontWeight: 'bold', fontSize: 30, color: 'white' }}
                >
                  Redeem a Code
                </h1>
                <div style={{ color: 'white', paddingBottom: 15 }}>
                  Redeem a code here to get access to a Wave Pass.
                </div>
                <Input
                  value={code}
                  onChange={handleChange}
                  placeholder="Enter your code here"
                  size="sm"
                  width="70%"
                />
                <div style={{ paddingTop: 15 }} />

                <Button
                  colorScheme="blue"
                  onClick={handleSubmit}
                  style={{ width: '30%' }}
                  // loadingText="Processing"
                  isLoading={apiLoading}
                >
                  Redeem
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {(isTablet || isMobile) && (
        <div>
          <Image
            src="/blurred_background.png"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              zIndex: -1,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, .9)', // Adjust the last value to increase/decrease darkness
              zIndex: -2, // Ensure it's above the image but below everything else
            }}
          ></div>
          <div
            style={{
              position: 'absolute',
              top: 40,
              right: 20,
              transform: 'translate(0%, -50%)',
              zIndex: 1,
            }}
          >
            <Button variant="solid" colorScheme='blue' onClick={goToProfile}>
              Profile
            </Button>
          </div>
          <div
            id="content-container"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '80%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              textAlign: 'center',
              color: '#fff',
            }}
          >
            {!loading && (
              <div>
                <h1
                  style={{ fontWeight: 'bold', fontSize: 30, color: 'white' }}
                >
                  Redeem a Code
                </h1>
                <div style={{ color: 'white', paddingBottom: 15 }}>
                  Redeem a code here to get access to a Wave Pass.
                </div>
                <Input
                  value={code}
                  onChange={handleChange}
                  placeholder="Enter your code here"
                  size="sm"
                  width="70%"
                />
                <div style={{ paddingTop: 15 }} />

                <Button
                  colorScheme="blue"
                  onClick={handleSubmit}
                  style={{ width: '30%' }}
                  // loadingText="Processing"
                  isLoading={apiLoading}
                >
                  Redeem
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </ChakraProvider>
  );
}

export default Redeem;
