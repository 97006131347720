import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Text,
  IconButton,
  Image,
  Link,
  Button,
  Divider,
  Center,
  Flex,
  HStack,
  VStack,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  useToast
} from '@chakra-ui/react';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { collection, query, getDocs, getDoc, doc, where } from 'firebase/firestore';
import { db, auth } from '../firebase-config';
import { useLocation, useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ButtonTheme from '../components/Button';
import { FaBullhorn } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive';
import { MyPaymentForm } from '../components/CheckoutDrawer';

function stripePromise() {
  return loadStripe(process.env.REACT_APP_STRIPE_KEY);
}

function EventPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const [data, setData] = useState({});
  // const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  // const [eventTime, setEventTime] = useState(null);
  // const [doorsOpen, setDoorsOpen] = useState(null);
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [clientSecret, setClientSecret] = useState('');
  const [intentPrice, setIntentPrice] = useState(0);
  const [buyNowButtonLoading, setBuyNowButtonLoading] = useState(false);
  const [currentPass, setCurrentPass] = useState("");
  const toast = useToast();
  let navigate = useNavigate();
  

  function closeDrawer() {
    onClose();
    setBuyNowButtonLoading(false);
  }

  function havePass(passes, passName) {
    let i = 0;
    for (i = 0; i < passes.length; i++) {
      if (passes[i].passName === passName) {
        return true;
      }
    }
    return false;
  }

  const drawerHook = async (price, passName) => {
    if (auth.currentUser == null) {
      goToLogin();
    } else {
      const docRef = doc(db, 'Users', auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      let tempPasses = docSnap.data()['passes'];
      if (
        havePass(
          tempPasses,
          passName
        )
      ) {
        toast({
          title: 'You already have this pass!',
          description: 'Download the app to view your passes and tickets.',
          status: 'info',
          duration: 9000,
          isClosable: true,
          position: 'bottom-right',
        });
        closeDrawer();
        return;
      } else {
        setBuyNowButtonLoading(true);
        fetch(
          'https://us-central1-wave-dynamics.cloudfunctions.net/stripePaymentIntentRequest',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: auth.currentUser.email,
              amount: price,
            }), // Replace with actual email and amount
          }
        )
          .then(response => response.json())
          .then(data => {
            setClientSecret(data.paymentIntent);
            setCurrentPass(passName);
            setIntentPrice(price);
            onOpen();
          })
          .catch(error => {
            console.error('Error fetching client secret:', error);
          });
      }
      
    }
  };

  const handleDirections = address => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
      address
    )}`;
    window.open(url, '_blank');
  };

  const linkToAppStore = () => {
    const url = "https://apps.apple.com/us/app/wave-vip/id6447489243";
    window.open(url,'_blank');
  }

  // const linkToMobileAppStore = () => {
  //   const url = "https://apps.apple.com/us/app/wave-vip/id6447489243";
  //   window.location.href = url; // Redirect to the link
  // };

  const goHome = () => {
    let path = '/';
    navigate(path);
  };

  useEffect(() => {
    const RouteChange = () => {
      let path = '/not-found';
      navigate(path);
    };

    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'featuredVenues'),
          where('id', '==', pathArray[2])
        );
        const querySnapshot = await getDocs(q);
        if (querySnapshot.size === 0) {
          RouteChange();
        } else {
          
          querySnapshot.forEach(doc => {
            setData(doc.data());
          });
        }
        if (auth.currentUser == null) {
          setLoggedIn(false);
        } else {
          setLoggedIn(true);
        }
      } catch (error) {
        console.error('Error fetching data from the database:', error);
      } finally {
        // setLoading(false);
      }
    };

    document.body.style.background = '#141416';
    fetchData();
    
    // console.log(data);
  }, [navigate, pathArray]);

  // useEffect(() => {
  //   if (data['comingSoon'] || !data['showVenue']) {
  //     goHome();
  //   }
  // }, [data]);

  // useEffect(() => {
  //   if (data.eventTime && data.eventTime.seconds) {
  //     const eventTimeSeconds = data.eventTime.seconds;
  //     const date = new Date(0); // Create a new date object with timestamp 0 (epoch)
  //     date.setUTCSeconds(eventTimeSeconds);
  //     setEventTime(date);
  //     const doorsOpenSeconds = data.doorsOpen.seconds;
  //     const doorsOpenDate = new Date(0); // Create a new date object with timestamp 0 (epoch)
  //     doorsOpenDate.setUTCSeconds(doorsOpenSeconds);
  //     setDoorsOpen(doorsOpenDate);
  //   }
  // }, [data]);

  // function to12HrTime(time) {
  //   let hrs = time.slice(0, 2);
  //   if (hrs >= 12) {
  //     return time.slice(0, 2) - 12 + ':' + time.slice(3, 5) + ' PM';
  //   } else {
  //     return time + 'AM';
  //   }
  // }

  // obtain client secret from stripe below

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  function goToLogin() {
    navigate(`/login?redirect=${pathArray[2]}`);
  }

  function goToProfile() {
    navigate('/profile');
  }

  return (
    <ChakraProvider theme={ButtonTheme}>
      {(isMobile || isTablet) && (
        <div>
          <img
            src={data.featuredImage}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: -2,
              filter: 'blur(20px) brightness(80%)',
              objectFit: 'cover',
            }}
            alt="blurryimage"
            className="blur-image"
          />
          <VStack>
            <div style={{ height: 90 }}></div>
            <div
              style={{
                position: 'absolute',
                top: 25,
                left: 20,
                // transform: 'translate(-50%, -50%)',
                zIndex: 2,
                width: "90%",
              }}
            >
              <a href="/">
                <img
                  style={{ width: 160, height: 50 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  alt="wavelogo"
                />
              </a>
            </div>
            <div
              style={{
                position: 'absolute',
                top: 50,
                right: 20,
                transform: 'translate(0%, -50%)',
                zIndex: 1,
              }}
            >
              {loggedIn ? (
                <Button variant="solid" onClick={goToProfile}>
                  Profile
                </Button>
              ) : (
                <>
                  <Button variant="solid" onClick={goToLogin}>
                    Login or Signup
                  </Button>
                </>
              )}
            </div>

            <Center>
              <Box
                bg="rgba(0, 0, 0, 0.5)" // Transparent black background
                borderRadius="15px" // Rounded corners
                p="4" // Padding
                color="#fff" // Text color
                width="90%"
              >
                <VStack>
                  <div
                    className="App"
                    style={{
                      width: "90%",
                    }}
                  >
                    {data.featuredImage && (
                      <VStack>
                        <Image
                          boxSize="250"
                          src={data.featuredImage}
                          style={{ borderRadius: 25 }}
                        />
                        <Box w={240}>
                          <Text
                            textColor="white"
                            fontSize={14}
                            textAlign={'center'}
                          >
                            With Wave, your ID is your ticket. No resellers and
                            no fake tickets means a safer night out for
                            everyone.
                          </Text>
                        </Box>
                      </VStack>
                    )}
                    <div>
                      <div>
                        <div style={{ paddingBottom: 0 }}>
                          <Box>
                            <p
                              style={{
                                color: 'white',
                                fontSize: '50px',
                                padding: 0,
                                lineHeight: '1.3', // Adjust the value as needed
                              }}
                            >
                              {data.name}
                            </p>
                            <p style={{ color: 'white', fontSize: '25px' }}>
                              {data.subtitle}
                            </p>
                          </Box>
                        </div>
                        <div style={{ height: 10 }} />
                        <Text color="white" display="flex" alignItems="center">
                          <Image
                            src="/tag-icon.png"
                            boxSize="5"
                            marginRight="0.5rem"
                            alt="Tag Icon"
                          />

                          {data.tags &&
                            data.tags.length > 0 &&
                            data.tags.map((item, index) => (
                              <React.Fragment key={index}>
                                {index !== 0 && ', '}
                                {item}
                              </React.Fragment>
                            ))}
                          {data.tags && data.tags.length === 0 && (
                            <React.Fragment>None</React.Fragment>
                          )}
                          <div style={{ width: 10 }} />
                          <Text
                            textColor="white"
                            display="flex"
                            alignItems="center"
                          >
                            <Image
                              src="/location-icon.png"
                              boxSize="5"
                              marginRight="0.5rem"
                            ></Image>
                            {data.location}
                          </Text>
                        </Text>

                        <div style={{ height: 15 }}></div>
                        {data.passAmount && (
                          <Accordion allowToggle>
                            <AccordionItem>
                              <div>
                                <h2>
                                  <AccordionButton>
                                    <Box
                                      as="span"
                                      flex="1"
                                      textAlign="left"
                                      color="white"
                                    >
                                      {data.passName}: ${data.passAmount / 100}
                                    </Box>
                                    <AccordionIcon color={'white'} />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Button
                                    // HERE NEEDS TO BE CHANGED TO ADD DRAWER & STRIPE
                                    variant="solid"
                                    onClick={() => drawerHook(data.passAmount, data.passName)}
                                    colorScheme="blue"
                                    isLoading={buyNowButtonLoading}
                                  >
                                    Buy Now
                                  </Button>
                                </AccordionPanel>
                              </div>
                            </AccordionItem>
                            {/* <AccordionItem>
                              <div>
                                <h2>
                                  <AccordionButton>
                                    <Box
                                      as="span"
                                      flex="1"
                                      textAlign="left"
                                      color="white"
                                    >
                                      Unlimited Pass: $75
                                    </Box>
                                    <AccordionIcon color={'white'} />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4}>
                                  <Button
                                    variant="solid"
                                    // HERE NEEDS TO BE CHANGED TO ADD DRAWER & STRIPE
                                    onClick={() => drawerHook(7500, "Unlimited Pass")}
                                    colorScheme="blue"
                                    isLoading={buyNowButtonLoading}
                                  >
                                    Buy Now
                                  </Button>
                                </AccordionPanel>
                              </div>
                            </AccordionItem> */}
                          </Accordion>
                        )}

                        <div style={{ height: 10 }}></div>
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            About
                          </Text>
                          <div style={{ height: 10 }} />

                          <Text textColor="white">
                            {data.ageLine1}{' '}
                            <Link
                              color="teal.500"
                              href={data.linkLine1}
                              isExternal
                            >
                              {data.linkLine1}
                            </Link>
                          </Text>
                          <br />
                          <Text textColor="white">{data.desc}</Text>
                          <br />
                          <Text textColor="white">
                            Wave Pass does not guarantee immediate entry.{' '}
                            <Link
                              color="teal.500"
                              href="https://www.wavenightlife.com/terms-conditions"
                              isExternal
                            >
                              Terms and Conditions
                            </Link>{' '}
                            apply.
                          </Text>
                          <br />
                          <Text textColor="white">
                            {data.line3}{' '}
                            <Link
                              color="teal.500"
                              href={data.linkLine3}
                              isExternal
                            >
                              {data.linkLine3}
                            </Link>
                          </Text>
                          <br />
                          <HStack>
                            <FaBullhorn style={{ color: 'white' }}></FaBullhorn>
                            <Text textColor="white">{data.line4}</Text>
                          </HStack>
                          <div style={{ height: 10 }} />
                        </div>
                        <Divider />
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                          <Text textColor="white" fontSize="25">
                            Venue
                          </Text>
                          <Text textColor="white" fontSize="35">
                            {data.name}
                          </Text>
                          <div style={{ height: 10 }} />
                          <Text textColor="white">{data.address}</Text>
                          <div style={{ height: 10 }} />
                          <Text textColor="white">Open from {data.hours}</Text>
                          <br></br>
                          {data.address && (
                            <Button
                              onClick={() => handleDirections(data.address)}
                              leftIcon={
                                <HiOutlineLocationMarker
                                  style={{ height: 50 }}
                                />
                              }
                            >
                              Open in Maps
                            </Button>
                          )}
                          <div style={{ height: 10 }} />
                        </div>
                        <Divider />

                        <HStack>
                          <Box w={400}>
                            <div
                              style={{
                                paddingTop: 10,
                                color: 'white',
                                fontSize: 25,
                              }}
                            >
                              Download the Wave App
                            </div>
                            <div style={{ height: 10 }} />
                            <Text color="white">
                              The best live shows, parties, and VIP experiences
                              - all on one app. Catch the Wave today.
                            </Text>
                            <div style={{ height: 25 }} />
                            <HStack>
                            <Button
                                  variant="solid"
                                  // leftIcon={<FaApple />}
                                  onClick={linkToAppStore}
                                  isDisabled
                                >
                                  Coming Soon
                                  {/* iOS */}
                                </Button>
                              {/* <Button
                                variant="solid"
                                leftIcon={<AiFillAndroid />}
                                onClick={() =>
                                  alert(
                                    'This will redirect to the android upon release'
                                  )
                                }
                              >
                                Android
                              </Button> */}
                            </HStack>
                          </Box>
                        </HStack>
                      </div>
                    </div>
                  </div>
                </VStack>
              </Box>
            </Center>
          </VStack>
        </div>
      )}
      {isDesktop && (
        <div>
          <img
            src={data.featuredImage}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: -2,
              filter: 'blur(20px) brightness(80%)',
              objectFit: 'cover',
            }}
            alt="blurryimage"
            className="blur-image"
          />
          <div
            id="content-container"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
              textAlign: 'center',
              color: '#fff',
            }}
          ></div>
          <VStack>
            <div style={{ height: 150 }}></div>
            <div
              style={{
                position: 'absolute',
                top: '80px',
                left: '200px',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
              }}
            >
              <a href="/">
                <img
                  style={{ width: 300, height: 100 }}
                  src="https://firebasestorage.googleapis.com/v0/b/wave-dynamics.appspot.com/o/web_app_storage%2Fwavelogo2.png?alt=media&token=38598297-8295-4ea2-94af-e1e76e3a3ca3"
                  alt="wavelogo"
                />
              </a>
            </div>
            <div
              style={{
                position: 'absolute',
                top: '80px',
                right: '50px',
                transform: 'translate(0%, -50%)',
                zIndex: 1,
              }}
            >
              {loggedIn ? (
                <Button variant="solid" onClick={goToProfile}>
                  Profile
                </Button>
              ) : (
                <>
                  <Button variant="solid" onClick={goToLogin}>
                    Login or Signup
                  </Button>
                </>
              )}
            </div>

            <Center>
              <Flex direction="row" alignItems="flex-start">
                <IconButton
                  icon={<ArrowBackIcon />}
                  onClick={goHome}
                  isRound
                  fontSize="24px"
                />
                <div style={{ width: 40 }} />
                <Box
                  bg="rgba(0, 0, 0, 0.5)" // Transparent black background
                  borderRadius="15px" // Rounded corners
                  p="4" // Padding
                  color="#fff" // Text color
                >
                  <Flex direction="row" alignItems="flex-start">
                    <HStack spacing={50}>
                      {data.featuredImage && (
                        <VStack>
                          <Image
                            boxSize="250"
                            src={data.featuredImage}
                            style={{ borderRadius: 25 }}
                          />
                          <Box w={240}>
                            <Text
                              textColor="white"
                              fontSize={14}
                              textAlign={'center'}
                            >
                              With Wave, your ID is your ticket. No resellers
                              and no fake tickets means a safer night out for
                              everyone.
                            </Text>
                          </Box>
                        </VStack>
                      )}
                      <div />
                    </HStack>
                    <HStack>
                      <div
                        className="App"
                        style={{
                          width: 700,
                        }}
                      >
                        <div>
                          <div style={{ paddingBottom: 0 }}>
                            <Box>
                              <p
                                style={{
                                  color: 'white',
                                  fontSize: '50px',
                                  padding: 0,
                                  lineHeight: '1.3', // Adjust the value as needed
                                }}
                              >
                                {data.name}
                              </p>
                              <p style={{ color: 'white', fontSize: '25px' }}>
                                {data.subtitle}
                              </p>
                            </Box>
                          </div>
                          <div style={{ height: 10 }} />
                          <Text
                            color="white"
                            display="flex"
                            alignItems="center"
                          >
                            <Image
                              src="/tag-icon.png"
                              boxSize="5"
                              marginRight="0.5rem"
                              alt="Tag Icon"
                            />

                            {data.tags &&
                              data.tags.length > 0 &&
                              data.tags.map((item, index) => (
                                <React.Fragment key={index}>
                                  {index !== 0 && ', '}
                                  {item}
                                </React.Fragment>
                              ))}
                            {data.tags && data.tags.length === 0 && (
                              <React.Fragment>None</React.Fragment>
                            )}
                            <div style={{ width: 10 }} />
                            <Text
                              textColor="white"
                              display="flex"
                              alignItems="center"
                            >
                              <Image
                                src="/location-icon.png"
                                boxSize="5"
                                marginRight="0.5rem"
                              ></Image>
                              {data.location}
                            </Text>
                          </Text>

                          <div style={{ height: 15 }}></div>
                          {data.passAmount && (
                            <Accordion allowToggle>
                              <AccordionItem>
                                <div>
                                  <h2>
                                    <AccordionButton>
                                      <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        color="white"
                                      >
                                        {data.passName}: $
                                        {data.passAmount / 100}
                                      </Box>
                                      <AccordionIcon color={'white'} />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    <Button
                                      variant="solid"
                                      // here
                                      onClick={() =>
                                        drawerHook(data.passAmount,data.passName)
                                      }
                                      colorScheme="blue"
                                      isLoading={buyNowButtonLoading}
                                    >
                                      Buy Now
                                    </Button>
                                  </AccordionPanel>
                                </div>
                              </AccordionItem>
                              {/* <AccordionItem>
                                <div>
                                  <h2>
                                    <AccordionButton>
                                      <Box
                                        as="span"
                                        flex="1"
                                        textAlign="left"
                                        color="white"
                                      >
                                        Unlimited Pass: $75
                                      </Box>
                                      <AccordionIcon color={'white'} />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    <Button
                                      variant="solid"
                                      // here
                                      onClick={() => drawerHook(7500, "Unlimited Pass")}
                                      colorScheme="blue"
                                      isLoading={buyNowButtonLoading}
                                    >
                                      Buy Now
                                    </Button>
                                  </AccordionPanel>
                                </div>
                              </AccordionItem> */}
                            </Accordion>
                          )}

                          <div style={{ height: 10 }}></div>
                          <Divider />
                          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Text textColor="white" fontSize="25">
                              About
                            </Text>
                            <div style={{ height: 10 }} />

                            <Text textColor="white">
                              {data.ageLine1}{' '}
                              <Link
                                color="teal.500"
                                href={data.linkLine1}
                                isExternal
                              >
                                {data.linkLine1}
                              </Link>
                            </Text>
                            <br />
                            <Text textColor="white">{data.desc}</Text>
                            <br />
                            <Text textColor="white">
                              Wave Pass does not guarantee immediate entry.{' '}
                              <Link
                                color="teal.500"
                                href="https://www.wavenightlife.com/terms-conditions"
                                isExternal
                              >
                                Terms and Conditions
                              </Link>{' '}
                              apply.
                            </Text>
                            <br />
                            <Text textColor="white">
                              {data.line3}{' '}
                              <Link
                                color="teal.500"
                                href={data.linkLine3}
                                isExternal
                              >
                                {data.linkLine3}
                              </Link>
                            </Text>
                            <br />
                            <HStack>
                              <FaBullhorn
                                style={{ color: 'white' }}
                              ></FaBullhorn>
                              <Text textColor="white">{data.line4}</Text>
                            </HStack>
                            <div style={{ height: 10 }} />
                          </div>
                          <Divider />
                          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            <Text textColor="white" fontSize="25">
                              Venue
                            </Text>
                            <Text textColor="white" fontSize="35">
                              {data.name}
                            </Text>
                            <div style={{ height: 10 }} />
                            <Text textColor="white">{data.address}</Text>
                            <div style={{ height: 10 }} />
                            <Text textColor="white">
                              Open from {data.hours}
                            </Text>
                            <br></br>
                            {data.address && (
                              <Button
                                onClick={() => handleDirections(data.address)}
                                leftIcon={
                                  <HiOutlineLocationMarker
                                    style={{ height: 50 }}
                                  />
                                }
                              >
                                Open in Maps
                              </Button>
                            )}
                            <div style={{ height: 10 }} />
                          </div>
                          <Divider />

                          <HStack>
                            <Box w={400}>
                              <div
                                style={{
                                  paddingTop: 10,
                                  color: 'white',
                                  fontSize: 25,
                                }}
                              >
                                Download the Wave App
                              </div>
                              <div style={{ height: 10 }} />
                              <Text color="white">
                                The best live shows, parties, and VIP
                                experiences - all on one app. Catch the Wave
                                today.
                              </Text>
                              <div style={{ height: 25 }} />
                              <HStack>
                                <Button
                                  variant="solid"
                                  // leftIcon={<FaApple />}
                                  onClick={linkToAppStore}
                                  isDisabled
                                >
                                  Coming Soon
                                  {/* iOS */}
                                </Button>
                                {/* <Button
                                  variant="solid"
                                  leftIcon={<AiFillAndroid />}
                                  onClick={() =>
                                    alert(
                                      'This will redirect to the android upon release'
                                    )
                                  }
                                >
                                  Android
                                </Button> */}
                              </HStack>
                            </Box>
                            <div style={{ width: 100 }} />
                            <Image src="/waveappicon1.png" boxSize="130" />
                          </HStack>
                        </div>
                      </div>
                    </HStack>
                  </Flex>
                </Box>
              </Flex>
            </Center>
          </VStack>
        </div>
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={closeDrawer}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            Complete your Purchase
          </DrawerHeader>
          <Elements stripe={stripePromise()} options={options}>
            <MyPaymentForm
              closeFunction={closeDrawer}
              passDetails={{
                passName: currentPass,
                datePurchased: new Date().toISOString(),
                isActive: true,
                eventTime: '',
                eventEndTime: '',
                venueId: data.venueId,
                passImage: data.passImage,
                ticketType: "venue"
                
              }}
              passPrice={intentPrice}
            ></MyPaymentForm>
          </Elements>
        </DrawerContent>
      </Drawer>
    </ChakraProvider>
  );
}

export default EventPage;
