import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';

function NotFound() {
  return (
    <ChakraProvider theme={theme}>
      <div className="App">
        <div style={{ textAlign: 'center' }}>Error 404: Page Not Found</div>
      </div>
    </ChakraProvider>
  );
}

export default NotFound;
